<!-- Componente Compañías Centrales (Finder) -->

<template>

  <div class="ciacM" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      persistent>
      
        <template v-slot:controles="{ }">
       
          <div>
            <div class="cabecera">     
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'
                @onEvent="eventHeader">
              </baseHeader>
            </div>

            <v-sheet :elevation="4">
              <div class="contenedor" style="width:1200px">
              
                <!-- Botones Mto -->
                <div v-if="!readOnly" class="pt-2 d-flex justify-left">

                  <!-- Mto -->
                  <baseBtraMto class="conflex"
                    :perm="permMto"
                    :modulo="btMtoCfg" 
                    :estado="estado"
                    @onEvent="execAccion">        
                  </baseBtraMto>

                  <!-- Extra -->            
                  <div class="conflex" style="margin-left: 100px">                         
                    <baseBtraExtra class="conflex"           
                      :permExtra="permExtra"
                      :modulo="btExtCfg"           
                      @onEvent="execAccion">             
                    </baseBtraExtra>                  
                  </div>

                </div>


                <div class="conflex" style="padding-top:10px">
                  <div class="columna" style="width:64%">
                    <div class="conflex">

                      <div class="columna" style="flex: 1 1 50%">
                        <div class="cab">DATOS GENERALES</div>
                        <v-sheet v-bind="$cfg.styles.marco">
                          <uiText                       
                          styleInput="font-weight: bold;"
                            v-model="ct.name[2]"
                            :label="ct.name[1]"
                            :disabled="noEdit">                      
                          </uiText>

                          <uiText                        
                            v-model="ct.dire[2]"
                            :label="ct.dire[1]"
                            :disabled="noEdit">                      
                          </uiText>

                          <ctrlF               
                            :ct="ct.loc"
                            :edicion="!noEdit">                        
                          </ctrlF>

                          <div class="conflex">
                            <uiText
                              style="width:49%"
                              v-model="ct.cp[2]"
                              :label="ct.cp[1]"                        
                              :disabled="noEdit">                      
                            </uiText>

                            <uiText
                              style="width:50%;margin-left:5px"
                              v-model="ct.cif[2]"
                              :label="ct.cif[1]"
                              :disabled="noEdit">                      
                            </uiText>
                          </div>
                        </v-sheet>
                      </div>                    

                      <!-- <div class="columna" style="flex: 1 1 50%">          
                        <div class="cab">FUNERARIA</div>
                        <v-sheet v-bind="$cfg.styles.marco">       
                         
                          <uiText       
                            style="flex: 1 1 70%"
                            v-model="ct.name_afecta_c[2]"
                            :label="ct.name_afecta_c[1]"
                            readonly>                      
                          </uiText>
                  
                          <uiText
                            v-model="ct.afecta_name[2]"
                            :label="ct.afecta_name[1]"
                            readonly>
                          </uiText>
                         

                          <div class="conflex"> 
                            <v-select
                              style="flex: 1 1 70%"
                              :items="$store.state.datos_iniciales.afecta_tipo"
                              :disabled="noEdit"
                              item-value="id"
                              item-text="name"
                              v-bind="$select"
                              v-model="ct.afecta_apd[2]">
                            </v-select>
                            
                            <v-checkbox
                              style="flex: 1 1 30%"
                              v-bind="$checkbox"
                              v-model="ct.liquidagencia[2]"
                              :label="ct.liquidagencia[1]"
                              :disabled="noEdit">
                            </v-checkbox>
                          </div>

                          <ctrlF                       
                            :ct="ct.afecta_f"
                            :edicion="!noEdit">
                          </ctrlF>

                        </v-sheet>
                      </div> -->
                    </div>

                    <div class="columna" style="flex: 1 1 50%">          
                      <div class="cab">DATOS CONTACTO</div>
                      <v-sheet v-bind="$cfg.styles.marco">          
                        <div class="conflex"> 
                          <uiText       
                            style="width:25%"
                            v-model="ct.tlf1[2]"
                            :label="ct.tlf1[1]"
                            :disabled="noEdit">                      
                          </uiText>                                                 
                        
                          <uiText          
                            style="width:24%;margin-left:5px"
                            v-model="ct.tlf2[2]"
                            :label="ct.tlf2[1]"
                            :disabled='noEdit'>
                          </uiText>
                      
                          <uiText
                            style="width:24%;margin-left:5px"
                            v-model="ct.tlf3[2]"
                            :label="ct.tlf3[1]"                            
                            :disabled="noEdit">
                          </uiText>
                                                 
                          <uiText       
                            style="width:24%;margin-left:5px"
                            v-model="ct.fax1[2]"
                            :label="ct.fax1[1]"
                            :disabled="noEdit">                      
                          </uiText>
                        </div>

                        <div class="conflex"> 
                          <!-- <v-checkbox
                            v-bind="$checkbox"
                            v-model="ct.representante[2]"
                            label=""
                            :disabled="noEdit">                          
                          </v-checkbox> -->                      
                      
                          <uiText
                            style="width:10%"
                            v-model="ct.cont1[2]"                            
                            :label="ct.cont1[1]"
                            :disabled="noEdit">
                          </uiText>
                          <uiText
                            style="width:10%"
                            v-model="ct.cont2[2]"
                            :label="ct.cont2[1]"
                            :disabled="noEdit">
                          </uiText>
                                                                        
                        </div>
                        
                        <div class="conflex">                         
                          <uiText
                            v-model="ct.email1[2]"
                            :label="ct.email1[1]"
                            :disabled="noEdit">                      
                          </uiText>                         
                          <uiText
                            style="margin-left:5px"
                            v-model="ct.email2[2]"
                            :label="ct.email2[1]"
                            :disabled="noEdit">                      
                          </uiText>
                        </div>
                      </v-sheet>
                    </div>
                    
                    <uiObs3                       
                      :record="record"
                      :schema="ct"
                      :schemaComponente="ct.obs"
                      :edicion="!noEdit">                       
                    </uiObs3>

                  </div>

                  <div class="columna" style="margin-left:10px;width:35%">
                    <div class="cab">GRUPO</div>
                    <v-sheet v-bind="$cfg.styles.marco">
                      <v-select
                        :items="itemsGrupo"
                        :disabled="noEdit"
                        item-value="id"
                        item-text="name"
                        v-bind="$select"
                        v-model="ct.grupo[2]"
                        :label="ct.grupo[1]">
                      </v-select>
                    </v-sheet>

                    <div class="cab">CLASIFICACIÓN</div>
                    <v-sheet v-bind="$cfg.styles.marco">
                      <div class="conflex">
                        <v-select
                          style="flex: 1 1 50%"
                          :items="$store.state.datos_iniciales.ciasitu"
                          :disabled="noEdit"
                          item-value="id"
                          item-text="label"
                          v-bind="$select"
                          v-model="ct.situ[2]"
                          :label="ct.situ[1]">
                        </v-select>

                        <v-select
                          style="flex: 1 1 50%"
                          :items="$store.state.datos_iniciales.ciastipo"
                          :disabled="noEdit"
                          item-value="id"
                          item-text="name"
                          v-bind="$select"
                          v-model="ct.tipo[2]"
                          :label="ct.tipo[1]">
                        </v-select>
                      </div>
                    
                      <uiText
                        v-model="ct.ccont[2]"
                        :label="ct.ccont[1]"
                        :disabled="noEdit">                      
                      </uiText>
                    </v-sheet>
                  
                    <div class="columna" style="flex: 1 1 50%">          
                      <div class="cab">FACTURACIÓN</div>
                      <v-sheet v-bind="$cfg.styles.marco">
                        <uiText
                          v-model="ct.admoncont[2]"
                          :label="ct.admoncont[1]"
                          :disabled="noEdit">                      
                        </uiText>

                        <div class="conflex">
                          <uiText
                            v-model="ct.admontlf1[2]"
                            :label="ct.admontlf1[1]"
                            :disabled="noEdit">                      
                          </uiText>                         
                                                 
                          <uiText
                            v-model="ct.admonfax1[2]"
                            :label="ct.admonfax1[1]"
                            :disabled="noEdit">                      
                          </uiText>
                        </div>

                        <div class="conflex">                                              
                          <uiText
                            v-model="ct.admonemail[2]"
                            :label="ct.admonemail[1]"
                            :disabled="noEdit">                      
                          </uiText>
                        </div>

                        <div class="conflex">                       
                          <uiText
                            v-model="ct.banco[2]"
                            :label="ct.banco[1]"
                            :disabled="noEdit">                      
                          </uiText>                        
                          <uiText
                            v-model="ct.cuenta[2]"
                            :label="ct.cuenta[1]"
                            format="banco"
                            :disabled="noEdit">                      
                          </uiText>
                        </div>                        

                      </v-sheet>
                    </div>
                  </div>
                </div>    
                  
              </div>
            </v-sheet>


            <!-- Ventana de Documentos -->      
            <component      
              :is="componenteDinamico"              
              :storeRaiz="storeName"
              :masterStore="storeName"              
              :readOnly="readOnly"
              :viewMtoOnly="viewMtoOnly"
              tipoDocumento='4'
              @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : ''">
            </component> 

          </div>
      </template>
    </dualTemplate>
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";  
  import baseHeader from "@/base/baseHeader";
  import baseBtraMto from "@/base/baseBtraMto"; 
  import baseBtraExtra from "@/base/baseBtraExtra";   
  import ctrlF from "@/components/ctrlF";
  import dualTemplate from "@/components/dualTemplate";
  import commonDocsF from "@/components/commonDocsF.vue";
   
  export default {
    mixins: [mixM],
    components: { baseHeader, baseBtraMto, baseBtraExtra, ctrlF, dualTemplate, commonDocsF },
    props: {},
    
    data() {
      return {
        stIni: {
          api: "ciacM",
          name:"ciacM",
          titulo:"Compañías Centrales",
          recordAccess:"local",
          mView:'ciacM',          // Módulo manteniniento asociado
          pView:[]                // Permisos del mantenimiento asociado
        },       

        itemsGrupo: [],

        // ct para mostrar el finder de CIAS desde botón
        ctSuc:["", "", "", [],	
          {
            comp: {
              type:"pickV1",
              api:"generic",
              source:"cias",
              fAux:"name",
              valueAux:"",
              finder:"ciasF",
              tipoView:"FA", // solo botón para ver el Mto
              mView:"",
              ctView:""            
            }
          }
        ],
      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // añado botones extra
        this.btExtCfg.btnsAccion= [
          { accion:'docs', btn: "docs" },
          { accion:'sucs', icono:"mdi-dots-vertical-circle-outline", title:"SUCURSALES" }
        ];

        //grupos añadir sin grupo
        this.itemsGrupo=this.$store.state.datos_iniciales.grupos.slice();
        this.itemsGrupo.unshift({id:'0',name:'* No Asignado'});
      },

      // gancho final para todas las operaciones de CRUD
      crudFin() {
        this.botonerasSet();
      },

      // desahbilitamos botones extra cuando se edita
      botonerasSet() {
        this.btnSet(this.btExtCfg, 'docs', { view: true, disabled:!this.noEdit? true : false });        
        this.btnSet(this.btExtCfg, 'sucs', { view: true, disabled:!this.noEdit? true : false });        
      },

      // COMPONENTES DINAMICOS (asignación)      
      docs() {
        this.componenteDinamico= 'commonDocsF';

      },

      // finder de sucursales
      sucs() {
        // guardo el nombre del store
        this.$root.$commonF.openFinder({ 
          ct:this.ctSuc, 
          componenteName:this.ctSuc[4].comp.finder,
          accion:"buscarCentral",
          store:this.storeName
        });
      }

    }
  };
</script>
