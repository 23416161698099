<!-- Componente Compañías Centrales (Finder) -->
  <!-- MODELO F sincronizado
 <ciacF>
    sync: { type: Boolean, default: false },              Sincronización con el MasterStore. True, el componente cambia de registro cada vez que cambia una fila.
    syncAccion: { type: String, default: '' },            Acción a ejecutar para cargar los records. Si no recibe nada, se utiliza lo que haya definido en autoLoad
    syncDisparo: { type:Boolean, default: false },        Switch que al cambiarlo ejecuta el disparo del componente
    storeRaiz: { type: String, default: "" },             Raiz del Store
    masterStore: { type: String, default: '' },           Nombre del store asociado
    masterRecordField: { type: String, default: 'id' },   Campo relacionado con el Maestro
    storeAux: { type: String, default: "" },              // store auxiliar
    recordAux: { type: Object, default: null },           // record auxiliar
    mView: { type: Object, default: '' },                 Mto que carga desde ctrlfinder
  </ciacF>

  MODELO M sincronizado
    <component
      :is="componenteDinamico"
      :sync=true
      :storeRaiz="raiz"
      :masterStore="raiz">
    </component>

  MODELO M con modal
  propiedad sync por defecto false
  Modal en store del mantenimiento true/false abrira mantenimiento
    <component
      :is="componenteDinamico"
      :storeRaiz="raiz"
      :masterStore="raiz">
    </component>



    Propiedades del Mto
    sync: { type: Boolean, default: false },
    syncAccion: { type: [String, Number], default: 0 },
    storeRaiz: { type: String, default: "" },
    masterStore: { type: String, default: '' },
    masterRecordField: { type: String, default: 'id' },
    auxRecord: { type: Object, default: null },

  -->


<template>
  <div class="ciacF" v-if="loaded">

    <div style="display:flex;justify-content:center">
      <v-sheet v-if="!viewMtoOnly" :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera">
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader">
          </baseHeader>
        </div>


        <!-- Contenido -->
        <div class="contenedor">

          <!-- <div v-html="info"></div> -->

          <div class="columna" style="width:50rem">
            <div class="conflex" style="width:100%">
              <uiText
                style="width:40%"
                v-model="ct.name[2]"
                :label="ct.name[1]"
                @Enter="eventFiltro('buscar')">
              </uiText>

              <v-btn style="margin-left:5px" v-bind="$cfe.btn.busca" @click="eventFiltro('buscar')">
                <v-icon dark>{{ $cfe.btn.busca.icono }}</v-icon>
              </v-btn>

              <v-checkbox
                  class="ml-2"
                  style="width:20%"
                  v-bind="$checkbox"
                  v-model="ct.bajas[2]"
                  :label="ct.bajas[1]">
                </v-checkbox>

                <ctrlF :ct="ct.loc" style="width:30%"></ctrlF>
            </div>


          </div>


          <!-- Grid -->
          <baseGridMD
            :store="storeName"
            :headers="headerGrid"
            :items-per-page="25"
            dense
            @onEvent="execAccion">

              <!-- Slot Botonera Top Grid -->
              <template v-slot:gridTop="{}">
                <div>
                  <baseBtraMto
                    :perm="permMto"
                    :modulo="btGridCfg"
                    @onEvent="execAccion">
                  </baseBtraMto>

                </div>
              </template>

              <template v-slot:name="{ item }">
                <!-- <div :style="item.baja=='1' ?'color:red;':'' + 'font-weight:bold'">{{  item.name  }}</div> -->
                <div :style="item.baja=='1'||item.situ=='BAJ' ?'color:red;':''">{{  item.name  }}</div>
              </template>

              <!-- Slot Acciones Row -->
              <template v-slot:acciones="{ item }">
                <baseBtraMto
                  :perm="permMto"
                  :modulo="btRowCfg"
                  @onEvent="execAccion({ ...$event, ...{item:item} })">
                </baseBtraMto>
              </template>

          </baseGridMD>
        </div>
      </v-sheet>


      <!-- Mto Compañías Centrales -->
      <component
        :is="componenteDinamico"
        :syncDisparo="syncDisparoSend"
        :syncAccion="syncAccionSend"
        syncUpdate
        :storeRaiz="storeName"
        :masterStore="storeName"
        :ctrlCt="ctrlCt"
        :readOnly="readOnly"
        :viewMtoOnly="viewMtoOnly"
        @onEvent="$emit('onEvent', $event)">
      </component>

    </div>
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto";
  import ctrlF from "@/components/ctrlF";
  import ciacM from "@/components/ciacM";

  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, baseBtraMto, ctrlF, ciacM },
    props: {},

    data() {
      return {
        stIni: {
          api: "ciacF",
          titulo:"·Compañías Centrales",
          name: "ciacF",
          mView: 'ciacM', // Módulo manteniniento asociado
          pView:[]        // Permisos del mantenimiento asociado
        },

      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // Cabecera Finder
        this.headers= [{
              // añadir en name los nombres de las cabeceras que coincidiran con las busquedas
              // si solo hubiera un elemento la cabecera tomará este por defecto
              // Si no encontrara el nombre pondría el elemento 0
              name:['buscar', 'busqueda1'],
              fields:[
                { text: "", value: "acciones", sortable: false, width: "15%", slot:true },
                { text: "COMPAÑIA", value: "name", width: "40%", slot: true },
                { text: "SIT", value: "situ", width: "5%" },
                { text: "TIPO", value: "tipo", width: "5%" },
                { text: "ATLAS", value: "atlas", width: "35%" }
            ]}
        ];

        this.headerGrid= this.headerSet();
      },

    }

  };

</script>
